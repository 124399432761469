import React, { Component } from "react";

import Calculator from "./Calculator";
import CustomerView from "./CustomerView";
import SaveDialog from "./SaveDialog";
import DialogBasic from "../../DialogBasic";
import _ from "lodash";
import SavedQuotes from "./SavedQuotes";
import { CircularProgress } from "@material-ui/core";

const Home = ({
  removeSave,
  setProjectTitle,
  products,
  admin,
  changeProduct,
  removeAddon,
  addons,
  processors,
  handleRadio,
  distributorStatus,
  closeDialog,
  saveModal,
  saveDialog,
  isSaveModalOpen,
  resetForm,
  config,
  user,
  openDialog,
  handleTab,
  handleInputChange,
  videoEnabled,
  openedTab,
  price,
  saveForm,
  isWritingToDb,
  item,
  handleQuote,
  saveLoad,
  handleAddonSelect,
  handleQty,
  appendInput,
  changePrice,
  updateTotalPricing,
  dimMode,
  enableView,
  changeInputValue,
  handleDimSelect,
  handleSelect,
  enableVideo,
  clearState,
  handleChecked,
}) => {
  const INTIAL_STATE = {
    snackbar: false,
    isSaveModalOpen: false,
    viewBar: true,
    linkDialog: false,
    welcome: true,
    leadGen: false,
    formActive: false,
    csvData: [],
    createAccount: true,
    timeline: "",
    resellerAccess: false,
    password: "",
    email: "",
    firstName: "",
    lastName: "",
    confirmPw: "",
    company: "",
    showErrors: false,
  };

  const [info, setInfo] = React.useState(INTIAL_STATE);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const closeWelcome = () => (e) => {
    setInfo({
      formActive: false,
    });
  };

  const fetchCSVData = () => {
    const csvData = [];

    //Product
    const totalPanels = +config.totalPanelsNeeded + +config.completePanels;
    const product = {
      qty: totalPanels,
      sku: config.currentProd.sku,
      unitPrice: config.currentProd.dealerPrice,
      subtotal: config.currentProd.dealerPrice * totalPanels,
    };
    //Cases
    const cases = {
      qty: config.caseNeeded,
      sku: "CASE - IRIS6",
      unitPrice: 299.99,
      subtotal: config.caseNeeded * 299.99,
    };
    //Bumpers
    const singleBumpers = {
      qty: config.singleBumpers,
      sku: "IRIS - FLYBAR-1",
      unitPrice: 254.99,
      subtotal: config.singleBumpers * 254.99,
    };
    const dualBumpers = {
      qty: config.dualBumpers,
      sku: "IRIS - FLYBAR-2",
      unitPrice: 324.99,
      subtotal: config.dualBumpers * 324.99,
    };
    //Processors

    let processors = [],
      processorsTotal = 0,
      // eslint-disable-next-line
      processorSum = _.map(config.processors, (processor) => {
        const processorSubTotal = +processor.qty * processor.dealerPrice;
        processorsTotal = +processorSubTotal + +processorsTotal;
        const data = {
          qty: processor.qty,
          sku: processor.sku,
          unitPrice: processor.dealerPrice,
          subtotal: processorSubTotal,
        };
        processors.push(data);
      });

    let addons = [],
      addonsTotal = 0,
      // eslint-disable-next-line
      addonSum = _.map(config.addons, (addon) => {
        if (addon.sku !== "None") {
          const addonSubTotal = +addon.qty * addon.dealerPrice;
          addonsTotal = +addonSubTotal + +addonsTotal;
          const data = {
            qty: addon.qty,
            sku: addon.sku,
            unitPrice: addon.dealerPrice,
            subtotal: addonSubTotal,
          };
          addons.push(data);
        }
      });

    const total =
      +product.subtotal +
      +cases.subtotal +
      +singleBumpers.subtotal +
      +dualBumpers.subtotal +
      +processorsTotal +
      +addonsTotal;
    const totaler = {
        qty: "",
        sku: "",
        unitPrice: "Total",
        subtotal: total + "*",
      },
      fineprint = {
        qty: "",
        sku: "",
        unitPrice: "",
        subtotal: "*Quote is an estimate. Does not include tax.",
      };

    csvData.push(
      product,
      cases,
      singleBumpers,
      dualBumpers,
      ...processors,
      ...addons,
      totaler,
      fineprint
    );

    setInfo({
      csvData: csvData,
    });
  };

  const closeLinkDialog = () => (e) => {
    setInfo({
      linkDialog: false,
    });
  };

  const renderCalc = () => {
    const {
      welcome,
      leadGen,
      formActive,
      password,
      email,
      firstName,
      lastName,
      confirmPw,
      resellerAccess,
      timeline,
      showErrors,
      company,
    } = info;

    const validatePw = password.length > 4;

    const showMessage = welcome;

    let savesPath = "/saves";

    if (admin) {
      savesPath = "/dealers";
    }
    return (
      <div className="calc">
        <Calculator
          removeSave={removeSave}
          isWritingToDb={isWritingToDb}
          setProjectTitle={setProjectTitle}
          products={products}
          user={user}
          changeProduct={changeProduct}
          removeAddon={removeAddon}
          addons={addons}
          processors={processors}
          handleRadio={handleRadio}
          openDialog={openDialog}
          admin={admin}
          distributorStatus={distributorStatus}
          handleTab={handleTab}
          handleAddonSelect={handleAddonSelect}
          changeInputValue={changeInputValue}
          appendInput={appendInput}
          handleQty={handleQty}
          changePrice={changePrice}
          item={item}
          config={config}
          saveLoad={saveLoad}
          clearState={clearState}
          handleChecked={handleChecked}
          handleInputChange={handleInputChange}
          saveForm={saveForm()}
          handleDimSelect={handleDimSelect}
          handleSelect={handleSelect}
        />
      </div>
    );
  };

  const renderStartCard = () => {
    if (config && config.saveLoad !== true) {
      const config = config,
        startMode =
          config.currentProd.sku === undefined && config.saveLoad !== true;
      if (startMode) {
        return (
          <div className="start-mode dialog">
            <h3>Start Here</h3>
            <p>Start by selecting a product</p>
          </div>
        );
      } else if (dimMode) {
        return (
          <div className="dim-mode dialog">
            <h3>Enter Dimensions</h3>
            <p>
              Pick from any two starting dimensions. We'll calculate the rest.
            </p>
          </div>
        );
      }
    }
  };

  const headers = [
    { label: "Qty", key: "qty" },
    { label: "Sku", key: "sku" },
    { label: "Unit Cost", key: "unitPrice" },
    { label: "Subtotal", key: "subtotal" },
  ];

  let quoteAvailable =
    openedTab !== "quoteTab" && config && config.widthSelected !== null;
  const { csvData } = info;

  let csvFilename = "untitled-project";

  if (config && config.saveName) {
    csvFilename = config.saveName;
  }

  const dealerAccess = admin || distributorStatus === "Dealer";

  return (
    <div className="home">
      <SaveDialog
        saveDialog={saveDialog}
        openDialog={openDialog}
        closeDialog={closeDialog}
        resetForm={resetForm}
      />

      {renderCalc()}
      <div className="view" style={{ maxWidth: "100%" }}>
        <div className="dash-bar">
          {quoteAvailable && (
            <>
              <div className="view-quote" onClick={handleTab("quoteTab")}>
                View Quote
              </div>
              <div
                className="view-quote"
                style={{ display: "flex", alignItems: "center" }}
                onClick={saveForm()}
              >
                Save
                {isWritingToDb ? (
                  <CircularProgress
                    style={{ marginLeft: 10 }}
                    color="inherit"
                    size={20}
                  />
                ) : null}
              </div>
            </>
          )}
        </div>

        <CustomerView
          updateTotalPricing={updateTotalPricing}
          addons={addons}
          handleQuote={handleQuote}
          distributorStatus={distributorStatus}
          admin={admin}
          viewBar={info.viewBar}
          handleTab={handleTab}
          openedTab={openedTab}
          videoEnabled={videoEnabled}
          item={item}
          config={config}
          price={price}
        />
      </div>
    </div>
  );
};

export default Home;
