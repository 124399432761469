import { Button } from "@material-ui/core";
import React from "react";
import logo from "../assets/images/blizzard-logo-for-dark-background.png";

const Header = () => {
  return (
    <div
      style={{
        backgroundColor: "black",
        padding: "1em",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div class="logo">
        <img src={logo} style={{ width: 150 }} />
      </div>
      <a
        href="https://www.blizzardpro.com"
        class="btn blue"
        style={{ borderRadius: 4 }}
      >
        Back to BLIZZARDPRO.COM ›
      </a>
    </div>
  );
};

export default Header;
