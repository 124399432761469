import React, { Component } from "react";
import _ from "lodash";
import QuoteRow from "./QuoteRow";
import { formatMoney } from "../../../utils";

let prodTotal = 0,
  addonTotal = 0,
  dbTotal = 0,
  sbTotal = 0,
  caseTotal = 0,
  recCardTotal = 0;

export default class QuoteTab extends Component {
  constructor(props) {
    super(props);
  }
  numberWithCommas = (x) => {
    if (x !== undefined) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  renderAddons = (type) => {
    const { config } = this.props;
    let addonSubTotal = 0,
      processorSubTotal = 0;

    const addonSum = _.map(config.addons, (addon) => {
      console.log(addon);
      const addonQty = addon.price * addon.qty;
      addonSubTotal = +addonQty + +addonSubTotal;
    });

    const processorSum = _.map(config.processors, (processor) => {
      const processorQty = processor.price * processor.qty;
      processorSubTotal = +processorQty + +processorSubTotal;
    });

    addonTotal = addonSubTotal + processorSubTotal;

    return _.map(config[type], (a, i) => {
      if (a.value !== "None") {
        let price = parseFloat(a.price);
        let note;
        if (type === "processors") {
          note = "7.5% tariff applied";
          price = price * 0.075 + price;
        }
        const priceQty = price * a.qty;

        return (
          <QuoteRow
            key={i}
            qty={a.qty}
            title={a.value || a.num}
            pricePer={formatMoney(price)}
            total={formatMoney(priceQty)}
            note={note}
          />
        );
      }
    });
  };

  renderProductRow() {
    if (this.props.config.totalPanelsNeeded) {
      const { config } = this.props,
        totalPanels =
          config.totalPanelsNeeded + parseInt(config.completePanels, 10);

      let price = 0;

      if (
        this.props.admin ||
        (this.props.distributorStatus &&
          this.props.distributorStatus !== "pending")
      ) {
        price = config.currentProd.dealerPrice;
        prodTotal = price * totalPanels;
      } else {
        price = config.currentProd.price;
        prodTotal = price * totalPanels;
      }

      return (
        <div className="columns csv-prod">
          <QuoteRow
            qty={totalPanels}
            title={config.currentProd.productName}
            pricePer={formatMoney(price)}
            total={formatMoney(prodTotal)}
          />
        </div>
      );
    }
  }

  renderCases() {
    const { config, addons } = this.props;
    const irisCase = _.find(addons, (r) => {
      return r.num.includes("CASE");
    });

    const price = parseFloat(irisCase.retail);
    caseTotal = price * config.caseNeeded;

    return (
      <div className="columns csv-prod">
        <QuoteRow
          qty={config.caseNeeded}
          title={irisCase.num}
          pricePer={formatMoney(price)}
          total={formatMoney(caseTotal)}
        />
      </div>
    );
  }

  renderSingleBumpers() {
    const { config, addons } = this.props;
    const flybar1 = _.find(addons, (r) => {
      return r.num.includes("FLY1");
    });

    if (this.props.config.panelWidthNeeded) {
      const price = parseFloat(flybar1.retail),
        total = price * config.singleBumpers;
      sbTotal = total;
      if (config.singleBumpers > 0) {
        return (
          <div className="columns csv-prod">
            <QuoteRow
              qty={config.singleBumpers}
              title={flybar1.num}
              pricePer={formatMoney(price)}
              total={formatMoney(total)}
            />
          </div>
        );
      }
    }
  }

  renderDualBumpers() {
    const { config, addons } = this.props;
    const flybar2 = _.find(addons, (r) => {
      return r.num.includes("FLY2");
    });

    if (this.props.config.panelWidthNeeded) {
      const price = parseFloat(flybar2.retail),
        total = price * config.dualBumpers;
      dbTotal = total;
      if (config.dualBumpers > 0) {
        return (
          <div className="columns csv-prod">
            <QuoteRow
              qty={config.dualBumpers}
              title={flybar2.num}
              pricePer={formatMoney(price)}
              total={formatMoney(total)}
            />
          </div>
        );
      }
    }
  }

  renderTotals() {
    const subTotal =
      +recCardTotal +
      +prodTotal +
      +caseTotal +
      +dbTotal +
      +sbTotal +
      +addonTotal;

    this.props.updateTotalPricing(this.numberWithCommas(subTotal.toFixed(2)));

    console.log(recCardTotal);
    console.log(prodTotal);
    console.log(caseTotal);
    console.log(dbTotal);
    console.log(sbTotal);
    console.log(addonTotal);

    return (
      <div className="columns" style={{ fontSize: "1.3em", marginTop: 30 }}>
        <div className="column" />
        <div className="column" />
        <div className="column" />
        <div className="column">
          {/* <strong>{this.props.config.price}</strong> */}
          <strong> $ {this.numberWithCommas(subTotal.toFixed(2))} </strong>
          <div className="fine">*Estimated cost before tax & shipping</div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div className="quote-form">
        <div
          className="columns"
          style={{
            borderBottom: "1px solid #1d6bbf",
          }}
        >
          <QuoteRow
            qty={"Qty"}
            title={"SKU"}
            pricePer={"Unit Cost"}
            total={"Subtotal"}
          />
        </div>
        {this.renderProductRow()}
        {this.renderCases()}
        {this.renderSingleBumpers()}
        {this.renderDualBumpers()}
        {/* {this.renderRecCard()} */}
        {this.renderAddons("addons")}
        {this.renderAddons("processors")}
        {this.renderTotals()}
      </div>
    );
  }
}
