import {productReducer} from "./product";
import {customerReducer} from "./customer";
import { combineReducers } from "@reduxjs/toolkit";
import {pricingReducer} from "./pricing";
import {metafieldsReducer} from "./metafields";
import {soReducer} from "./so";
import {contactReducer} from "./contact";
import {addressReducer} from "./address";

const allReducers = combineReducers({
  products: productReducer,
  customer: customerReducer,
  pricing: pricingReducer,
  so: soReducer,
  metafields: metafieldsReducer,
  contacts: contactReducer,
  addresses: addressReducer,
});

export default allReducers;
