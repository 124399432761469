const DefaultState = {
  loading: false,
  reseller: [],
  rep: [],
  errorMsg: "",
};

export const soReducer = (state = DefaultState, action) => {
  switch (action.type) {
    case "GET_SO_LOADING":
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case "GET_SO_FAIL":
      return {
        ...state,
        loading: false,
        errorMsg: "Eror Loading SO",
      };
    case "GET_SO_SUCCESS":
      return {
        ...state,
        loading: false,
        reseller: action.payload,
        errMsg: "",
      };
    case "GET_REP_SO_LOADING":
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case "GET_REP_SO_FAIL":
      return {
        ...state,
        loading: false,
        errorMsg: "Eror Loading SO",
      };
    case "GET_REP_SO_SUCCESS":
      return {
        ...state,
        loading: false,
        rep: action.payload,
        errMsg: "",
      };
    default:
      return state;
  }
};
