import axios from "axios";
import React, { useEffect, useState, createContext } from "react";
import { app, atFullApp, db } from "../base.js";
import { API_URL } from "../config.js";
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [userToken, setUserToken] = useState();
  const [atFullUID, setAtFullUID] = useState();
  const [atFullToken, setAtFullToken] = useState();
  const [userAs, setUserAs] = useState();
  const [writeAccess, setWriteAccess] = useState();
  const [currentUser, fetchUser] = useState();
  const docRef = db.collection("user");
  const localUser = localStorage.getItem("userAs");

  const clearUserAs = () => {
    setUserAs();
    localStorage.removeItem("userAs");
    window.location.href = "/";
  };

  const fetchTempUser = (uid) => {
    docRef
      .doc(uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log(doc.data());
          fetchUser(doc.data());
        } else {
          console.log("No such user!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const determineWriteAccess = (accountLevel) => {
    switch (accountLevel) {
      case "Superadmin":
        setWriteAccess(true);
      case "Editor":
        setWriteAccess(true);
      default:
        return;
    }
  };

  const fetchUserAs = (uid) => {
    console.log("fetch user as");
    docRef
      .doc(uid)
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          try {
            const userData = await doc.data();
            setUserAs(userData);
            determineWriteAccess(userData.accountLevel);
          } catch (err) {
            console.log(err);
          }

          localStorage.setItem("userAs", JSON.stringify(doc.data()));

          window.location.href = "/";
        } else {
          console.log("No such user!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const changeUserStatus = (status, uid, type) => {
    docRef
      .doc(uid)
      .update({
        [type]: status,
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const fetchCurrentUser = async () => {
    const { currentUser } = await app.auth();
    let userUid;

    if (currentUser && currentUser.uid) {
      userUid = currentUser.uid;
    }

    if (userUid) {
      docRef
        .doc(userUid)
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            const userData = await doc.data();
            determineWriteAccess(userData.accountLevel);
            setUser(userData);
          } else {
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    }
  };

  const fetchUserFromFirebase = (authUser) => {
    const portalListener = app.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        fetchCurrentUser();
        app
          .auth()
          .currentUser.getIdToken(true)
          .then((idToken) => {
            setUserToken(idToken);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setIsLoading(false);
      }
    });
    return portalListener;
  };

  const fetchUserFromAtFull = async (authUser) => {
    const atFullListener = atFullApp
      .auth()
      .onAuthStateChanged(async (authUser) => {
        if (authUser) {
          const currentCustomClaims = authUser;
          atFullApp
            .auth()
            .currentUser.getIdToken(true)
            .then((idToken) => {
              setAtFullUID(authUser.uid);
              setAtFullToken(idToken);
            })
            .catch((error) => {
              console.log(error);
            });
          const claims = await atFullApp.auth().currentUser.getIdTokenResult();
        } else {
          setIsLoading(false);
        }
      });
    return atFullListener;
  };

  useEffect(async () => {
    if (localUser) {
      setUserAs(true);
      setUser({ ...JSON.parse(localUser), userAs: true });
    } else {
      fetchUserFromFirebase();
      fetchUserFromAtFull();
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        changeUserStatus,
        user,
        isLoading,
        userAs,
        writeAccess,
        fetchUserAs,
        clearUserAs,
        userToken,
        currentUser,
        atFullToken,
        atFullUID,
        fetchTempUser,
        fetchUserFromFirebase,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
