import React from "react";
import { Button } from "@material-ui/core";
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <div className="container">
            <i
              style={{ fontSize: "3em", color: "#282bf6" }}
              className="fal fa-skull-crossbones"
            ></i>
            <h2>Ooops! Something went wrong.</h2>
            <Button
              onClick={() => (window.location.href = "/")}
              type="submit"
              style={{ width: "48%", background: "#282bf6" }}
              variant="contained"
              color="primary"
              className="btn"
            >
              Return
            </Button>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
