const DefaultState = {
  loading: false,
  errorMsg: "",
  current: [],
  resellers: [],
};

export const customerReducer = (state = DefaultState, action) => {
  switch (action.type) {
    case "GET_CUSTOMERS_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "GET_CUSTOMERS_FAIL":
      return {
        ...state,
        loading: false,
        errorMsg: "Error loading customers",
      };
    case "GET_CUSTOMERS_SUCCESS":
      return {
        ...state,
        loading: false,
        all: action.payload,
      };
    case "GET_CURRENT_CUSTOMER_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "GET_CURRENT_CUSTOMER_FAIL":
      return {
        ...state,
        loading: false,
        errorMsg: "Error loading current customer",
      };
    case "GET_CURRENT_CUSTOMER_SUCCESS":
      return {
        ...state,
        loading: false,
        current: action.payload,
      };
    case "GET_REP_CUSTOMERS_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "GET_REP_CUSTOMERS_FAIL":
      return {
        ...state,
        loading: false,
        errorMsg: "Error loading REP customer",
      };
    case "GET_REP_CUSTOMERS_SUCCESS":
      return {
        ...state,
        loading: false,
        resellers: action.payload,
      };
    default:
      return state;
  }
};
