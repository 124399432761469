import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { UserProvider } from "./context/User";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import QuoteBuilder from "./components/Quote Builder/QuoteBuilder";
import { ErrorBoundary } from "./components/ErrorBoundary";

const App = () => {
  const theme = createMuiTheme({
    typography: {
      fontFamily: "Poppins",
    },
    palette: {
      primary: { main: "#282bf6" },
      secondary: { main: "#081016" },
      alert: { main: "#f44336" },
    },
    status: {
      danger: { 500: "#ff4d4d" },
    },
  });

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <UserProvider>
          <Router>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <>
                <Route exact path="/" component={() => <QuoteBuilder />} />
              </>
            </MuiPickersUtilsProvider>
          </Router>
        </UserProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
