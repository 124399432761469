import { useEffect, useRef } from "react";
import _ from "lodash";
import { states, countries } from "../temp/index.js";
import axios from "axios";
import { API_URL } from "../config";

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const updateHubspotContact = (obj, userToken) => {
  axios.post("https://api.blizzard.lighting/update-contact", obj, {
    timeout: 10000,
    headers: {
      authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    },
  });
};
export const updateHubspotCompany = (obj, userToken) => {
  axios.post("https://api.blizzard.lighting/update-company", obj, {
    timeout: 10000,
    headers: {
      authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    },
  });
};

export const createMarkup = (markup) => {
  return {
    __html: markup,
  };
};

export const generateInviteCode = () => {
  var firstPart = (Math.random() * 46656) | 0;
  var secondPart = (Math.random() * 46656) | 0;

  firstPart = ("000" + firstPart.toString(36)).slice(-5);
  secondPart = ("000" + secondPart.toString(36)).slice(-5);
  return firstPart + secondPart;
};

export const findState = (id) => {
  return _.find(states, (o) => {
    return o.id === id;
  });
};
export const findCountry = (id) => {
  return _.find(countries, (o) => {
    return o.id === id;
  });
};

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let colour = "";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  if (parseInt(colour, 16) > 15658734) return "#081016";
  return `#${colour}`;
}

export function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

export const generateKey = () => {
  return Math.floor(Math.random() * 1000000000);
};

export function generateUID() {
  // I generate the UID from two parts here
  // to ensure the random number provide enough bits.
  var firstPart = (Math.random() * 46656) | 0;
  var secondPart = (Math.random() * 46656) | 0;
  firstPart = ("000" + firstPart.toString(36)).slice(-9);
  secondPart = ("000" + secondPart.toString(36)).slice(-9);
  return firstPart + secondPart;
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function determineFileType(fileExt) {
  const supportedImageTypes = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/svg",
    "image/ico",
  ];
  if (supportedImageTypes.includes(fileExt)) {
    return "image";
  } else return null;
}

export function determineRepFirm(id) {
  switch (id) {
    //Brownestone
    case 81:
    case 82:
      return 1285;
    //Audio Biz
    case 10:
    case 27:
    case 28:
    case 33:
    case 56:
      return 52;
    //Audio Pros
    case 69:
    case 70:
      return 1185;
    //Cadon
    case 75:
    case 76:
      return 1237;
    //Salesforce
    case 54:
    case 41:
      return 889;
    //Holloway
    case 64:
    case 66:
      return 1147;
    //Thor
    case 104:
    case 105:
      return 1690;
    //Metro Joes
    case 17:
    case 52:
      return 314;
    //JAMS
    case 67:
    case 68:
      return 1167;
    //RTM
    case 53:
    case 21:
      return 437;
    //Dobbs
    case 93:
      return 1500;
    default:
      return 0;
  }
}

export function parseMetafieldsByType(data, descriptions) {
  const metafields = data;
  const metafieldTypes = [
    { id: 1, type: "Text" },
    { id: 6, type: "Checkbox" },
    { id: 9, type: "Long Text" },
  ];

  const typeIdToString = (id) => {
    switch (id) {
      case "1":
        return "Text";
      case "6":
        return "Checkbox";
      case "9":
        return "Long Text";
      default:
        return "Unsupported type";
    }
  };

  let combineMetafieldsDescriptions = [];

  _.map(descriptions, (o) => {
    let match = _.filter(metafields, (x, i) => {
      return i === o.id;
    });

    combineMetafieldsDescriptions.push({
      ...o,
      type: typeIdToString(o.customFieldTypeId),
      ...match[0],
    });
  });

  let filteredMetafields = {
    shopify: [],
    pricing: [],
    dimentional: [],
    amazon: [],
  };

  _.map(combineMetafieldsDescriptions, (m, i) => {
    if (m.description === "Shopify") {
      filteredMetafields["shopify"].push(m);
    } else if (m.description === "Pricing") {
      filteredMetafields["pricing"].push(m);
    } else if (m.description === "Dimensions") {
      filteredMetafields["dimentional"].push(m);
    } else if (m.description === "Amazon") {
      filteredMetafields["amazon"].push(m);
    }
  });
  return filteredMetafields;
}

export function parseMetafields(metafields, query) {
  const results = _.filter(metafields, (x) => {
    return x.name === query;
  });
  if (results.length) {
    return results[0].value;
  } else {
    return 0;
  }
}

export function getDimensionsFromFishbowl(metafields, type) {
  let dimensions = "";

  if (metafields) {
    const queryValues = ["Weight", "Height", "Length", "Qty", "Width"];
    const results = _.map(metafields, (x) => {
      queryValues.map((query) => {
        if (x.name.includes(query)) {
          if (x.name.includes(type)) {
            const key = query.toLowerCase();
            dimensions = {
              ...dimensions,
              [key]: parseFloat(x.value),
            };
          }
        }
      });
    });
    return dimensions;
  }
  return;
}

export function handleDownload(photo) {
  const xhr = new XMLHttpRequest();
  var filename = /[^/]*$/.exec(photo)[0];
  xhr.responseType = "blob";
  xhr.onload = function () {
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(xhr.response);
    a.download = filename; // Name the file anything you'd like.
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
  };
  xhr.open("GET", photo);
  xhr.send();
}

export async function updateProductInFishbowl(objArr, callback) {
  try {
    const response = await axios.post(`${API_URL}update-product`, objArr);
    console.log(response);
    callback();
  } catch (err) {
    console.log(err);
  }
}
export async function updatePartInFishbowl(objArr, callback) {
  try {
    const response = await axios.post(`${API_URL}update-part`, objArr);
    console.log(response);
    callback();
  } catch (err) {
    console.log(err);
  }
}

export function formatMoney(number) {
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(number);
}

export function convertFishbowlJSON(string) {
  return string
    .replace(/[0-9`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
    .toLowerCase();
}

export function convertSpecialChars(string) {
  if (typeof string !== "string") {
    return string;
  }
  return string
    .replace(/—/g, "&#8212")
    .replace(/–/g, "&ndash;")
    .replace(/•/g, "&bull;")
    .replace(/™/g, "&trade;")
    .replace(/-/g, "&#45;")
    .replace(/°/g, "&deg;")
    .replace(/“/g, "&#34;")
    .replace(/”/g, "&#34;")
    .replace(/®/g, "&reg;")
    .replace(/'/g, "&apos;")
    .replace(/’/g, "&apos;")
    .replace(/›/g, "&rsaquo;");
}
