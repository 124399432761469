import axios from "axios";
import { API_URL } from "../config";

export const getProducts = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_PRODUCTS_LOADING",
    });
    const res = await axios.get(`${API_URL}products`);
    dispatch({
      type: "GET_PRODUCTS_SUCCESS",
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: "GET_PRODUCTS_FAILED",
      payload: e,
    });
  }
};
export const getIrisPanels = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_PANELS_LOADING",
    });
    const res = await axios.get(`${API_URL}iris-panels`);
    dispatch({
      type: "GET_PANELS_SUCCESS",
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: "GET_PANELS_FAILED",
      payload: e,
    });
  }
};

export const getIrisProcessors = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_PROCESSORS_LOADING",
    });
    const res = await axios.get(`${API_URL}iris-processors`);
    dispatch({
      type: "GET_PROCESSORS_SUCCESS",
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: "GET_PROCESSORS_FAILED",
      payload: e,
    });
  }
};

export const getIrisAddons = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_ADDONS_LOADING",
    });
    const res = await axios.get(`${API_URL}iris-addons`);
    dispatch({
      type: "GET_ADDONS_SUCCESS",
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: "GET_ADDONS_FAILED",
      payload: e,
    });
  }
};

export const getTariffs = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_TARIFFS_LOADING",
    });
    const res = await axios.get(`${API_URL}get-novastar-tariff`);
    dispatch({
      type: "GET_TARIFFS_SUCCESS",
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: "GET_TARIFFS_FAILED",
      payload: e,
    });
  }
};
