import React from "react";
import _ from "lodash";
import { db } from "../../../base";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const SavedQuotes = ({ user, close }) => {
  const [savedQuotes, setSavedQuotes] = React.useState([]);

  React.useEffect(() => {
    let quotes = [];
    db.collection("savedWallQuotes")
      .where("owner", "==", user.uid)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          quotes.push(doc.data());
        });
        setSavedQuotes(quotes);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const renderSavedQuotes = () => {
    if (savedQuotes.length > 0) {
      return _.map(savedQuotes, (quote) => {
        return (
          <div className="line-item">
            {quote.projectTitle}
            <Button
              type="submit"
              style={{ width: "50%", marginTop: 30 }}
              variant="contained"
              color="primary"
              className="btn"
              onClick={() => close()}
              component={Link}
              to={`/iris-quote-builder?project=${quote.uid}`}
            >
              Open
            </Button>
          </div>
        );
      });
    } else {
      return <p>No quotes found</p>;
    }
  };
  return <div className="add-prompt">{renderSavedQuotes()}</div>;
};

export default SavedQuotes;
